//libs
import { CSG } from 'three-csg-ts';

//models
import { HouseType } from "./model/houseType";
import { RoofType } from "./model/roofType";
import { ModelDimensions } from "./model/modelDimensions";
import { HouseBaseModel } from "./model/houseBaseModel";
import { CardinalDirection } from "@/properties/model/cardinalDirection";
import { FloorType } from "@/properties/model/floorType";
import { ExtrudeGeometry, Group, Mesh, MeshBasicMaterial, Object3D, Shape, Texture, TextureLoader } from 'three';

export const defaultDimensions: ModelDimensions = {
    walls: {
        [CardinalDirection.NORTH]: {
            width: 8,
            height: 3,
            depth: 0.4
        },
        [CardinalDirection.SOUTH]: {
            width: 8,
            height: 3,
            depth: 0.4
        },
        [CardinalDirection.WEST]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
        [CardinalDirection.EAST]: {
            width: 10,
            height: 3,
            depth: 0.4
        }
    },
    floors: {
        [FloorType.ERDGESCHOSS]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
        [FloorType.MITTELGESCHOSS]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
        [FloorType.DACHGESCHOSS]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
    },
    roof: {
        [CardinalDirection.WEST]: {
            width: 10,
            height: 3,
            depth: 0.4,
            degree: 30
        },
        [CardinalDirection.EAST]: {
            width: 10,
            height: 3,
            depth: 0.4,
            degree: 30
        }
    }
}

export class SingleFamilyHome extends HouseBaseModel {
    brickMats: MeshBasicMaterial | undefined = undefined;
    roofMats: MeshBasicMaterial | undefined = undefined;

    constructor(customDimensions = defaultDimensions, customRoofType?: RoofType, customMats?: any) {
        super(customDimensions, HouseType.SINGLE_FAMILY_HOME, customRoofType ?? RoofType.GABLE);

        if(customMats){
            if(customMats["wall"]){
                this.brickMats = customMats["wall"];
            }

            if(customMats["roof"]){
                this.roofMats = customMats["roof"];
            }
        }
    }

    private createWall(width: number, height: number, depth: number, color: number, amountWindows = 0, amountDoors = 0): Mesh {
        let newWall = this.createBoxGeometry(width, height, depth, color);
        newWall.position.set(width / 2, height / 2, 0);
        newWall.updateMatrix();


        const wallSegmets = amountWindows + amountDoors;
        const segmentWidth = width / wallSegmets;
        const segmentInfo: any = [];

        for (let iSeg = 0; iSeg < wallSegmets; iSeg++) {
            const info = {
                width: segmentWidth,
                segmentCenterX: iSeg * (segmentWidth / 2) + (segmentWidth / 2),
                segmentCenterY: 0.5 * height,
                segmentCeterZ: 0
            };

            segmentInfo.push(info);
        }


        for (let i = 0; i < amountWindows; i++) {
            const wallCSG = CSG.fromMesh(newWall);

            const winBox = this.createBoxGeometry(0.8, 1.4, 0.4, 0xFFFFFF);
            winBox.position.set(i * (segmentWidth) + (segmentWidth / 2), 0.5 * height, 0);
            winBox.updateMatrix();


            newWall = CSG.toMesh(wallCSG.subtract(CSG.fromMesh(winBox)), newWall.matrix, newWall.material);
        }

        if(this.brickMats){
            newWall.material = this.brickMats;
        }

        return newWall;
    }

    public validateDimensions(): boolean {
        return true;
    }

    public createModel(): Object3D {
        const houseGroup = new Group();

        return houseGroup;
    }

    public createDefaultModel(): Object3D {
        const houseGroup = new Group();

        {   // NORTH
            const firstFloor = this.createWall(8, 3, 0.4, 0xa33514, 2);
            if(this.brickMats){
                firstFloor.material = this.brickMats;
            }
            firstFloor.position.set(4, 1.5, 0);
            houseGroup.add(firstFloor);

            if (this.roofType === RoofType.FLAT) {
                const secondFloor = this.createWall(8, 3, 0.4, 0xa33514, 2);
                secondFloor.position.set(4, 4.5, 0);
                houseGroup.add(secondFloor);
            }
        }

        {   // EAST
            const firstFloor = this.createWall(9.2, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(7.8, 1.5, 4.8);
            this.rotateObject(firstFloor, 0, 90);
            houseGroup.add(firstFloor);

            if (this.roofType === RoofType.FLAT) {
                const secondFloor = this.createWall(9.2, 3, 0.4, 0xa33514, 2);
                secondFloor.position.set(7.8, 4.5, 4.8);
                this.rotateObject(secondFloor, 0, 90);
                houseGroup.add(secondFloor);
            }
        }

        {   // SOUTH
            const firstFloor = this.createWall(8, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(4, 1.5, 9.6);
            houseGroup.add(firstFloor);

            if (this.roofType === RoofType.FLAT) {
                const secondFloor = this.createWall(8, 3, 0.4, 0xa33514, 2);
                secondFloor.position.set(4, 4.5, 9.6);
                houseGroup.add(secondFloor);
            }
        }


        {   // WEST
            const firstFloor = this.createWall(9.2, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(0.2, 1.5, 4.8);
            this.rotateObject(firstFloor, 0, 90);
            houseGroup.add(firstFloor);

            if (this.roofType === RoofType.FLAT) {
                const secondFloor = this.createWall(9.2, 3, 0.4, 0xa33514, 2);
                secondFloor.position.set(0.2, 4.5, 4.8);
                this.rotateObject(secondFloor, 0, 90);
                houseGroup.add(secondFloor);
            }
        }

        {   // BOTTOM PLATE <-> GROUND FLOOR
            const floorObj = this.createBoxGeometry(7.2, 0.4, 9.2, 0xb4b4b4);
            floorObj.position.set(4, 0.2, 4.8);
            houseGroup.add(floorObj);

        }

        {   // FIRST FLOOR <-> SECOND FLOOR
            const floorObj = this.createBoxGeometry(7.2, 0.4, 9.2, 0xb4b4b4);
            floorObj.position.set(4, 2.8, 4.8);

            this.highlightObject([floorObj])
            houseGroup.add(floorObj);
        }


        if (this.roofType === RoofType.GABLE) {
            const roofGrp = new Group();

            const roofEast = this.createBoxGeometry(10.2, 6.8, 0.2, 0x6a6a6b);
            if(this.roofMats){
                roofEast.material = this.roofMats;
            }
            if (Array.isArray(roofEast.material)) {
                roofEast.material[0].transparent = true;
                roofEast.material[0].opacity = 0.25;
            } else {
                const mat = <MeshBasicMaterial>roofEast.material;
                mat.transparent = true;
                mat.opacity = 0.25;
            }

            roofGrp.add(roofEast);

            const roofWest = this.createBoxGeometry(10.2, 7, 0.2, 0x6a6a6b);
            this.rotateObject(roofWest, 90);
            roofWest.position.set(0, 3.5, 3.4);

            if(this.roofMats){
                roofWest.material = this.roofMats;
            }

            if (Array.isArray(roofWest.material)) {
                roofWest.material[0].transparent = true;
                roofWest.material[0].opacity = 0.25;
            } else {
                const mat = <MeshBasicMaterial>roofWest.material;
                mat.transparent = true;
                mat.opacity = 0.25;
            }

            roofGrp.add(roofWest);
            this.rotateObject(roofGrp, 0, 90);
            this.rotateObject(roofGrp, 45);

            roofGrp.position.set(1.5, 4.5, 4.8);


            houseGroup.add(roofGrp);

            const secondToAttic = this.createBoxGeometry(3.3, 0.3, 9.2, 0xb4b4b4);
            secondToAttic.position.set(4, 5.2, 4.8);
            houseGroup.add(secondToAttic);

            //Gable
            const gableShape = new Shape()
                .moveTo(0.1, 3)
                .lineTo(7.9, 3)
                .lineTo(4, 7)
                .lineTo(0.1, 3);
            {
                const extrudeSettings = { depth: 0.4, bevelEnabled: false };
                const geometry = new ExtrudeGeometry(gableShape, extrudeSettings);
                let material = new MeshBasicMaterial({ color: 0xa33514 });
                
                if(this.brickMats){
                    material = this.brickMats
                }
                material.transparent = true;
                material.opacity = 0.25

                let gableNorth: any = new Mesh(geometry, material);
                gableNorth.translateZ(-0.2)

                gableNorth.updateMatrix();

                const gableCSG = CSG.fromMesh(gableNorth);

                const winBox = this.createBoxGeometry(0.8, 1.4, 0.4, 0xFFFFFF);
                winBox.position.set(3.95, 4.2, 0);
                winBox.updateMatrix();


                gableNorth = CSG.toMesh(gableCSG.subtract(CSG.fromMesh(winBox)), gableNorth.matrix, gableNorth.material);

                houseGroup.add(gableNorth);
            }

            {
                const extrudeSettings = { depth: 0.4, bevelEnabled: false };
                const geometry = new ExtrudeGeometry(gableShape, extrudeSettings);
                let material = new MeshBasicMaterial({ color: 0xa33514 });
                if(this.brickMats){
                    material = this.brickMats
                }
                material.transparent = true;
                material.opacity = 0.25

                let gableSouth: any = new Mesh(geometry, material);
                gableSouth.translateZ(9.4)

                gableSouth.updateMatrix();

                const gableCSG = CSG.fromMesh(gableSouth);

                const winBox: any = this.createBoxGeometry(0.8, 1.4, 0.4, 0xFFFFFF);
                winBox.position.set(3.95, 4.2, 9.6);
                winBox.updateMatrix();


                gableSouth = CSG.toMesh(gableCSG.subtract(CSG.fromMesh(winBox)), gableSouth.matrix, gableSouth.material);
                houseGroup.add(gableSouth);
            }

        } else if (this.roofType === RoofType.FLAT) {
            const flatRoof = this.createBoxGeometry(7.2, 0.4, 9.2, 0xb4b4b4);
            flatRoof.position.set(4, 5.8, 4.8);
            houseGroup.add(flatRoof);
        } else if (this.roofType === RoofType.HIP) {
            // invalid rooftype
            console.warn("Single family homes do not support hip roofs!");
        }

        return houseGroup;
    }
}
//libs

//models
import { HouseType } from "./houseType";
import { RoofType } from "./roofType";
import { ModelDimensions } from "./modelDimensions";
import { BoxGeometry, MathUtils, Mesh, MeshBasicMaterial, Object3D } from "three";

// TODO: Custom Validator für ModelDimensions, der durch erbende Klassen implementiert wird
export class HouseBaseModel {
    private _houseType: HouseType;
    private _roofType: RoofType;

    private _modelDimensions: ModelDimensions;

    private highlightInterval: number | undefined = undefined;

    constructor(modelDimensions: ModelDimensions, houseType: HouseType, roofType: RoofType) {
        this._modelDimensions = modelDimensions;
        this._houseType = houseType;
        this._roofType = roofType;
    }

    protected rotateObject(object: Object3D, degreeX = 0, degreeY = 0, degreeZ = 0) {
        if (degreeX !== 0) {
            object.rotateX(MathUtils.degToRad(degreeX));
        }

        if (degreeY !== 0) {
            object.rotateY(MathUtils.degToRad(degreeY));
        }

        if (degreeZ !== 0) {
            object.rotateZ(MathUtils.degToRad(degreeZ));
        }
    }

    protected createBoxGeometry(width: number, height: number, depth: number, color = 0x00000): Mesh {
        const geometry = new BoxGeometry(width, height, depth);
        const material = new MeshBasicMaterial({ color: color });
        material.transparent = true;
        material.opacity = 0.25;

        return new Mesh(geometry, material);
    }

    // nur eine Gruppe von objects auf einmal
    protected highlightObject(objects: Mesh[]) {
        if (this.highlightInterval !== undefined) {
            clearInterval(this.highlightInterval);
        }

        this.highlightInterval = setInterval(() => {
            for (let i = 0; i < objects.length; i++) {

                // zeitbasierte Sinus-Funktion für fade in / fade out Effekt
                if (Array.isArray(objects[i].material)) {
                    objects[i].material[0].opacity = 1.25 + 1 * Math.sin(new Date().getTime() * .0025);
                } else {
                    const mat = <MeshBasicMaterial>objects[i].material;
                    mat.opacity = 1 + 1 * Math.sin(new Date().getTime() * .0025);
                    mat.color.setHex(0xe3382b);
                }
            }
        }, 50);
    }

    /** getter & setter */
    public get houseType() {
        return this._houseType;
    }

    public get roofType() {
        return this._roofType;
    }

    public set roofType(newType: RoofType) {
        this._roofType = newType;
    }

    public get modelDimensions() {
        return this._modelDimensions;
    }

    public set modelDimensions(newDimensions: ModelDimensions) {
        this._modelDimensions = newDimensions;
    }

    /** class logic */
    public validateDimensions(): boolean {
        return true;
    }


    /** three model creation */
    public createModel(): void {
        return;
    }
}